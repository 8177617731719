
import { Component, Vue } from 'vue-property-decorator';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import { Inject } from 'inversify-props';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import { BRAND_ANY, BRAND_BLANK } from '@/modules/cars/constants/car-filter-types.constant';
import Item from '@/modules/common/interfaces/item.interface';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class CarBrandFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    private query: string | null = null;

    get items(): Item<string>[] {
        const { brandsFilter } = this.carsFiltersService;
        let filteredBrands = brandsFilter;
        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            filteredBrands = filteredBrands.filter(brand => searchPattern.test(brand.toLowerCase()));
        }
        const brands = [BRAND_ANY, BRAND_BLANK, ...filteredBrands].map(item => ({ name: item, value: item }));
        return brands;
    }

    get currentValue(): string {
        return this.sippSettingsService.carBrand || BRAND_ANY;
    }

    set currentValue(value: string) {
        this.sippSettingsService.carBrand = value || BRAND_ANY;
    }

    handleChange(query: string) {
        this.query = query;
    }
}
